
import { defineComponent } from "vue";
import { Form, Field } from "vee-validate";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Form,
    Field,
    Swal,
    Datatable,
  },
  data() {
    return {
      constInput: 2,
      batch: {},
      employee: {},
      details: {},
      weekplan: {},
      employmentBillList: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],

      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "150px",
        },
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
        },
        {
          name: "TI",
          key: "ti",
          sortable: true,
        },
        {
          name: "Course",
          key: "course",
          sortable: true,
        },
        {
          name: "BN",
          key: "bn",
          sortable: true,
        },
        {
          name: "EN #",
          key: "en",
          sortable: true,
        },

        {
          name: "AN #	",
          key: "an",
          sortable: true,
        },
        {
          name: "CN #",
          key: "cn",
          sortable: true,
        },
        {
          name: "Unit Cost",
          key: "unit_cost",
          sortable: true,
        },
        {
          name: "Pay %",
          key: "pay_percent",
          sortable: true,
        },
        {
          name: "Payment Amount",
          key: "payment_amount",
          sortable: true,
        },
        {
          name: "	Bill Amount",
          key: "bill_amount",
          sortable: true,
        },
        {
          name: "New Unit Cost	",
          key: "new_unit_cost",
          sortable: true,
        },
        {
          name: "New Payment Amount	",
          key: "new_payment_amount",
          sortable: true,
        },
        {
          name: "New Bill Amount	",
          key: "new_bill_amount",
          sortable: true,
        },
        {
          name: "Adj. Amount",
          key: "adj_amount",
          sortable: true,
        },
      ],
      tableData: [
        {
          sl: 1,
          ti: 5,
          course: 44,
          bn: "পাইথন এডভান্সড",
          en: "Python Advanced",
          an: "510232100",
          cn: "19861591906025850",
          unit_cost: 198,
          pay_percent: 87,
          payment_amount: 45,
          bill_amount: 78,
          new_unit_cost: 56,
          new_payment_amount: 858,
          new_bill_amount: 678,
          adj_amount: 78,
        },
        {
          sl: 2,
          ti: 5,
          course: 45,
          bn: "এসকিউএল কোর্স",
          en: "SQL Course",
          an: "510232100",
          cn: "19861591906025850",
          unit_cost: 198,
          pay_percent: 87,
          payment_amount: 45,
          bill_amount: 78,
          new_unit_cost: 56,
          new_payment_amount: 858,
          new_bill_amount: 678,
          adj_amount: 78,
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
    // await this.getEmploymentBillList();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEmploymentBillList() {
      await ApiService.get("resource/employment-bill-list")
        .then((response) => {
          this.employmentBillList = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    courseNotice() {
      // if (Object.keys(this.employmentBillList).length === 0) {
      //   console.log("No Assessor List");
      //   Swal.fire({
      //     title: "Loading Data..",
      //     text: "Please Wait While the Data is Loading",
      //     icon: "info",
      //     showCancelButton: false,
      //     showConfirmButton: false,
      //   });
      //   this.getEmploymentBillList().then(() => {
      //     Swal.close();
      //     this.showCourseNotice = true;
      //   });
      // } else {
      //   this.showCourseNotice = true;
      // }
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },
  },
  setup() {
    const AssociationSchema = [];
    return {
      AssociationSchema,
    };
  },
});
